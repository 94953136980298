.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #00295E;
  background-color: #fff;
  border-color: none;
  font-weight: 700;
}

.nav-tabs {
  border-bottom: none;
  background-color: #00295E;  
}

.nav-tabs .nav-link{
  color: white
}

.nav-tabs .nav-link:hover{
  color: gray
}