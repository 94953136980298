.App {
  text-align: center;
}

.container-login{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}



.loading-blur{
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 10000;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
}

.loading-blur.inactive{
  opacity: 0;
  z-index: 0;
  transition: 300ms;
}


.db-success{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 50;
  height: 50;
  opacity: 1;
  transition: 300ms;
}

.db-success.inactive{
  width: 20;
  height: 20;
  opacity: 0;
  transition: 300ms;
}

.db-error{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 50;
  height: 50;
  opacity: 1;
  transition: 300ms;
}

.db-error.inactive{
  width: 20;
  height: 20;
  opacity: 0;
  transition: 300ms;
}

